import { JSXElement, lazy } from 'solid-js';
import { getSlug, HandlersPath } from '~/components/AppRouter/utils.ts';
import { Route } from '@solidjs/router';

const TestSSOHandler = lazy(
  () => import('~/pages/Handlers/TestSSOHandler.tsx')
);

const LoginCallbackHandler = lazy(
  () => import('~/pages/Handlers/AuthCallback.tsx')
);

const LoginBox = lazy(() => import('~/pages/Handlers/LoginBox'));

export default function UiHandlerRoutes(): JSXElement {
  return (
    <Route path={getSlug.getHandlersBaseNamespace()}>
      <Route
        path={getSlug.handlers(HandlersPath.TEST_SSO)}
        component={TestSSOHandler}
      />
      <Route
        path={getSlug.handlers(HandlersPath.LOGIN_CALLBACK)}
        component={LoginCallbackHandler}
      />
      <Route
        path={getSlug.handlers(HandlersPath.LOGIN_BOX)}
        component={LoginBox}
      />
    </Route>
  );
}
