import { JSXElement, mergeProps } from 'solid-js';
import { useFormContext } from '~/components/Form/FormContext.tsx';
import { ServerErrorType } from '~/components/Form/utils.ts';

type ServerErrorProps = {
  children: (e: ServerErrorType) => JSXElement;
};

export function ServerError(props: ServerErrorProps): JSXElement {
  const merged = mergeProps({}, props);
  const formAccessors = useFormContext();

  return <>{merged.children(formAccessors.serverError)}</>;
}
