export enum ConnectionTypes {
  SAML = 'SAML',
  OIDC = 'OIDC',
  OAUTH = 'OAUTH'
}

export enum SSOProviders {
  ONELOGIN = 'ONELOGIN',
  OKTA = 'OKTA',
  GOOGLE = 'GOOGLE',
  MICROSOFT_AD = 'MICROSOFT_AD',
  PING_IDENTITY = 'PING_IDENTITY',
  JUMPCLOUD = 'JUMPCLOUD',
  CUSTOM = 'CUSTOM',
  IDP_SIMULATOR = 'IDP_SIMULATOR'
}

export enum SocialProviders {
  GITHUB = 'GITHUB',
  GOOGLE = 'GOOGLE',
  GITLAB = 'GITLAB',
  MICROSOFT_AD = 'MICROSOFT',
  LINKEDIN = 'LINKEDIN',
  SALESFORCE = 'SALESFORCE'
}

export enum DirectoryProviders {
  ONELOGIN = 'ONELOGIN',
  OKTA = 'OKTA',
  MICROSOFT_AD = 'MICROSOFT_AD',
  PING_IDENTITY = 'PING_IDENTITY',
  JUMPCLOUD = 'JUMPCLOUD',
  CUSTOM = 'DIRECTORY_PROVIDER_UNSPECIFIED'
}

export enum ScalekitInternalProviders {
  SCALEKIT = 'SCALEKIT',
  IDP_SIMULATOR = 'IDP_SIMULATOR'
}

export type Providers =
  | SSOProviders
  | SocialProviders
  | ScalekitInternalProviders
  | DirectoryProviders;

export enum ConnectionStatuses {
  DRAFT = 'DRAFT',
  INPROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum ConfigurationTypes {
  CONFIGURATION_TYPE_UNSPECIFIED = 'CONFIGURATION_TYPE_UNSPECIFIED',
  DISCOVERY = 'DISCOVERY',
  MANUAL = 'MANUAL'
}

export enum DomainMappingRefTypes {
  CONNECTION = 'CONNECTION'
}

export interface DomainMapping {
  id?: string;
  domain: string;
  referenceId?: string;
  referenceType?: DomainMappingRefTypes;
  txtRecordSecret?: string;
  valid?: boolean;
}

export interface ConnectionData {
  id: string;
  type: ConnectionTypes;
  configurationType: ConfigurationTypes;
  provider: SSOProviders;
  status: ConnectionStatuses;
  enabled?: boolean;
  debugEnabled?: boolean;
  organizationId?: string;
  uiButtonTitle?: string;
  loginInitiationUri?: string;
  logoutUri?: string;
  testConnectionUri?: string;
  domainMappings?: DomainMapping[];
  attributeMapping: Record<string, any>;
  oidcConfig?: Record<string, any>;
  samlConfig?: Record<string, any>;
  oauthConfig?: Record<string, any>;
}

export interface UpdateConnectionDataRequest {
  id: string;
  configurationType?: ConfigurationTypes;
  oidcConfig?: Record<string, any>;
  samlConfig?: Record<string, any>;
  oauthConfig?: Record<string, any>;
}

export interface OIDCMetadata {
  issuer: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userinfoEndpoint?: string;
  jwksUri?: string;
}

export interface SAMLIdpCertificate {
  certificate: string;
  createTime: string;
  expiryTime: string;
  id: string;
  issuer: string;
}

export interface SAMLMetadata {
  idpEntityId: string;
  idpSsoUrl: string;
  idpSloUrl: string;
  idpCertificate: string;
  idpNameIdFormat: string;
  requestBinding: string;
  wantAssertionsSigned: boolean;
}

export interface ConnectionsResponse {
  connections: ConnectionData[];
}

export interface CreateConnectionRequest {
  provider: Providers;
  type: ConnectionTypes;
}

export interface ConnectionUpdateResponse {
  connection: ConnectionData;
}

export interface ToggleConnectionResponse {
  enabled: boolean;
}

export enum SAML_SIGNING_OPTIONS {
  NO_SIGNING = 'NO_SIGNING',
  SAML_ONLY_RESPONSE_SIGNING = 'SAML_ONLY_RESPONSE_SIGNING',
  SAML_ONLY_ASSERTION_SIGNING = 'SAML_ONLY_ASSERTION_SIGNING',
  SAML_RESPONSE_ASSERTION_SIGNING = 'SAML_RESPONSE_ASSERTION_SIGNING'
}

export enum TestConnectionResultStatus {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

export interface TestConnectionResult {
  status: TestConnectionResultStatus;
  userInfo?: string;
  error?: string;
  errorDescription?: string;
  errorDetails?: string;
}
