import { JSXElement } from 'solid-js';
import { Localized } from '~/i18n';
import { getTranslationKey } from '~/pages/AdminPortal/utils.ts';

export default function (): JSXElement {
  return (
    <div
      class={`rounded-md py-8 px-[140px] text-center text-fg-muted border border-e-bg-emphasized`}
    >
      <div class={`text-lg font-medium`}>
        <Localized
          translationKey={getTranslationKey('header.no_features_empty_state')}
        />
      </div>
      <div class={`mt-6 text-fg-muted text-sm`}>
        <Localized
          translationKey={getTranslationKey('header.contact_support')}
        />
      </div>
    </div>
  );
}
