import { JSXElement, mergeProps, splitProps } from 'solid-js';
import { Button, ButtonProps } from '~/components/ui/Button';
import { useFormContext } from '~/components/Form/FormContext.tsx';
import { hasFieldError } from '~/components/Form/utils.ts';

type SubmitButtonProps = ButtonProps & {
  allowUnchanged?: boolean;
};

export default function SubmitButton(props: SubmitButtonProps): JSXElement {
  const merged = mergeProps(
    {
      allowUnchanged: false
    },
    props
  );
  // excluding properties that are defined in this component
  const [componentProps] = splitProps(merged, ['allowUnchanged']);
  const [, buttonProps] = splitProps(merged, ['type', 'disabled']);
  const formAccessors = useFormContext();

  return (
    <Button
      {...buttonProps}
      type={'submit'}
      loading={formAccessors.isSubmitting()}
      disabled={
        hasFieldError(formAccessors.errors()) ||
        (!componentProps.allowUnchanged && !formAccessors.isChanged())
      }
    >
      {merged.children}
    </Button>
  );
}
