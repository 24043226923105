import { GroupRoleMapping } from '~/api/types/roleData.ts';
import { DirectoryProviders } from '~/api/types/connectionData.ts';

export enum DirectoryTypes {
  SCIM = 'SCIM'
}

export enum DirectoryStatuses {
  INPROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  UNSPECIFIED = 'DIRECTORY_STATUS_UNSPECIFIED'
}

export enum DerivedDirectoryStatuses {
  WAITING_TO_CONNECT = 'WAITING_TO_CONNECT',
  CONNECTED = 'CONNECTED',
  READY_TO_SETUP = 'READY_TO_SETUP',
  LISTENING_FOR_EVENTS = 'LISTENING_FOR_EVENTS',
  DIRECTORY_LINKED = 'DIRECTORY_LINKED'
}

export interface DirectorySecret {
  id: string;
  createTime: string;
  secretSuffix: string;
  status: 'ACTIVE' | 'INACTIVE';
  expireTime: string;
  lastUsedTime: string;
  directoryId: string;
}

export interface DirectoryStats {
  totalUsers: number;
  totalGroups: number;
  groupUpdatedAt: number;
  userUpdatedAt: number;
}

export interface DirectoryData {
  id: string;
  name: string;
  directoryType: DirectoryTypes;
  directoryEndpoint: string;
  organizationId: string;
  source: string;
  enabled: boolean;
  secrets: DirectorySecret[];
  directoryProvider: DirectoryProviders;
  status: DirectoryStatuses;
  mappings: Array<Record<string, any>>;
  lastSyncedAt?: string;
  totalUsers: number;
  totalGroups: number;
  stats: DirectoryStats;
  roleAssignments?: {
    assignments: GroupRoleMapping[];
  };
}

export interface CreateDirectoryRequest {
  directoryType: DirectoryTypes;
  directoryProvider: DirectoryProviders;
}

export interface ListDirectoryResponse {
  directories: DirectoryData[];
}

export interface CreateDirectoryResponse {
  directory: DirectoryData;
}

export interface GetDirectoryByIdResponse {
  directory: DirectoryData;
}

export interface DirectoryGroup {
  id: string;
  displayName: string;
  totalUsers: number;
  updatedAt: string;
}

export interface DirectoryUser {
  id: string;
  email: string;
  preferredUsername: string;
  givenName: string;
  familyName: string;
  emails: string[];
  groups: DirectoryGroup[];
  updatedAt: string;
  userDetail?: Record<any, any>;
  userDetailRaw?: string;
}

export interface ListDirectoryUserRequest {
  organizationId: string;
  directoryId: string;
  pageSize: number;
  pageToken: string;
  groupId?: string;
}

export interface ListDirectoryUserResponse {
  totalSize: number;
  nextPageToken: number;
  prevPageToken: number;
  users: DirectoryUser[];
}

export interface ListDirectoryGroupRequest {
  organizationId: string;
  directoryId: string;
  pageSize: number;
  pageToken: string;
}

export interface ListDirectoryGroupResponse {
  totalSize: number;
  nextPageToken: number;
  prevPageToken: number;
  groups: DirectoryGroup[];
}

export interface GenerateDirectorySecretRequest {
  organizationId: string;
  directoryId: string;
  shouldRegenerate?: boolean;
}

export interface GenerateDirectorySecretResponse {
  plainSecret: string;
  secret: DirectorySecret;
}
