import { withMakeRequest } from '~/api';
import Request from '~/api/Request.ts';

export interface OnboardRequest {
  workspaceDisplayName: string;
  userGivenName: string;
  userFamilyName: string;
}

export interface OnboardResponse {}

const onboard: (request: OnboardRequest) => Promise<boolean> = withMakeRequest(
  async ({ ...request }: OnboardRequest) =>
    await Request.getInstance().patch(`/workspaces:onboard`, {
      ...request
    })
);

export { onboard };
