import { Component, JSXElement, Match, Show, Switch } from 'solid-js';
import {
  FeatureFlagData,
  FeatureFlagKeys,
  FeatureFlagMap,
  FeatureSettings
} from '~/api/types/featureFlagData.ts';
import { Navigate } from '@solidjs/router';
import { getSlug } from '~/components/AppRouter/utils.ts';
import { OrganizationData } from '~/api';
import { KeyRoundIcon, RefreshCcwIcon } from 'lucide-solid';
import { cloneObject } from '~/utils/commonUtils.ts';
import { globalStore, setGlobalStore } from '~/stores';
import SkLoader from '~/components/ui/SkLoader';

function isFeatureEnabled(feature: FeatureFlagKeys): boolean {
  return !!globalStore.featureFlags?.[feature]?.value;
}

const transformFeatureFlagResponse: (
  flags: FeatureFlagData[] | null
) => FeatureFlagMap = (flags) => {
  return !flags
    ? null
    : (flags.reduce((acc, flag) => {
        return {
          ...acc,
          [flag.key as FeatureFlagKeys]: cloneObject(flag)
        };
      }, {}) as FeatureFlagMap);
};

const overrideFeatureFlagWithEnvFeatures: (
  featureFlagMap: FeatureFlagMap,
  environmentFeature: Partial<Record<FeatureFlagKeys, boolean>>
) => FeatureFlagMap = (featureFlagMap, environmentFeatures) => {
  featureFlagMap = featureFlagMap || ({} as FeatureFlagMap);
  Object.keys(environmentFeatures).forEach((featureKey) => {
    if (featureFlagMap?.[featureKey]) {
      featureFlagMap[featureKey].value =
        featureFlagMap[featureKey].value === true
          ? !!environmentFeatures[featureKey]
          : featureFlagMap[featureKey].value;
    } else {
      featureFlagMap![featureKey] = {
        value: !!environmentFeatures[featureKey],
        key: featureKey
      };
    }
  });
  return featureFlagMap;
};

function withFeatureFlag<T extends object>(
  component: Component<T>,
  feature: FeatureFlagKeys
) {
  const Renderer = component;
  return (props: T) => {
    return (
      <Switch>
        <Match when={!globalStore.featureFlags}>
          <SkLoader />
        </Match>
        <Match when={globalStore.featureFlags}>
          <Show
            when={globalStore.featureFlags && isFeatureEnabled(feature)}
            fallback={
              <Navigate href={`${getSlug.workspaceBaseNamespace()}/`} />
            }
          >
            <Renderer {...props} />
          </Show>
        </Match>
      </Switch>
    );
  };
}

type FeatureDisplayConfigType = {
  displayName: string;
  icon: JSXElement;
};

const FEATURE_FLAG_DISPLAY_CONFIG: Partial<
  Record<FeatureFlagKeys, FeatureDisplayConfigType>
> = {
  [FeatureFlagKeys.SINGLE_SIGNON]: {
    displayName: 'Single Sign On',
    icon: <KeyRoundIcon width={20} />
  },
  [FeatureFlagKeys.DIRECTORY_SYNC]: {
    displayName: 'SCIM Provisioning',
    icon: <RefreshCcwIcon width={20} />
  }
};

const isFeatureEnabledForOrg = (
  organization: OrganizationData,
  feature: FeatureFlagKeys
): boolean =>
  getFlatEnabledFeaturesArrayFromOrgFeatures(
    organization.settings?.features ?? []
  ).includes(feature);

const getPortalFeaturesDisplayNames = (
  features: FeatureFlagKeys[]
): string[] => {
  return features
    .map((feature: string) => FEATURE_FLAG_DISPLAY_CONFIG[feature]?.displayName)
    .filter((displayName) => !!displayName);
};

const getFlatEnabledFeaturesArrayFromOrgFeatures = (
  features: FeatureSettings[]
): FeatureFlagKeys[] =>
  features.filter(({ enabled }) => !!enabled).map(({ name }) => name);

const isGbraEnabledForEnvironment = (): boolean =>
  !!globalStore.featureFlags?.[FeatureFlagKeys.GBRA]?.value;

const updateFeature = (feature: FeatureFlagKeys, value: boolean) => {
  setGlobalStore('featureFlags', (featureFlags) => ({
    ...featureFlags,
    [feature]: {
      ...featureFlags?.[feature],
      value
    }
  }));
};

export {
  isFeatureEnabled,
  transformFeatureFlagResponse,
  withFeatureFlag,
  overrideFeatureFlagWithEnvFeatures,
  FEATURE_FLAG_DISPLAY_CONFIG,
  isFeatureEnabledForOrg,
  getPortalFeaturesDisplayNames,
  getFlatEnabledFeaturesArrayFromOrgFeatures,
  isGbraEnabledForEnvironment,
  updateFeature
};
